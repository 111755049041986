import React, { useEffect, useState } from "react";
import "./index.scss";

const HorizontalScrollIndicator = () => {
    const [_, forceUpdate] = useState(0);

    useEffect(() => {
        const scrollHandler = () => {
            forceUpdate(prev => prev + 1)
        };
        
        document.addEventListener('scroll', scrollHandler);
        return () => document.removeEventListener('scroll', scrollHandler)
    }, [])

    const getProgress = () => {
        let offsetY = window.scrollY;
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        );
        let root = document.getElementById("root")?.clientHeight - vh;
        return (offsetY / root) * 100;
    };

    return (
        <div className="horizontal-indicator">
            <div
                className="progress-bar"
                style={{
                    width: getProgress() + "%",
                }}
            ></div>
        </div>
    );
};

export default HorizontalScrollIndicator;
