import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Actions from "../../../redux/actions";
import { IoIosLogIn, IoLogoFacebook, IoLogoGoogle } from "react-icons/io";

import Logo from "../../../assets/logo.png";
import Button from "../../../components/Button";
import FloatInput from "../../../components/FloatInput";
import BasePopup from "../base-popup";
import {
    signInWithFacebook,
    signInWithGoogle,
    signOutUser,
} from "../../../lib/API/auth";
import { generateUniqueKey, Validate } from "../../../utilites";

import "./index.scss";

const Popup = (props) => {
    const { onClose = () => {} } = props;
    const dispatch = useDispatch();

    const [firstTry, setFirstTry] = useState(true);
    const [loginForm, setLoginForm] = useState({
        email: {
            value: "",
            valid: true,
            errMsg: "",
            rules: ["required", "email"],
        },
        password: {
            value: "",
            valid: true,
            errMsg: "",
            rules: ["required"],
        },
    });

    const onChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let newState = { ...loginForm };
        let validationObj = Validate(value, loginForm[name].rules);
        newState[name].valid = validationObj.valid;
        newState[name].errMsg = validationObj.msg;
        newState[name].value = value;

        setLoginForm(newState);
    };

    const handleSubmit = (e) => {
        signOutUser();
        e.preventDefault();
        let formIsValid = true;
        let newState = { ...loginForm };
        let validationObj;
        for (let key in loginForm) {
            validationObj = Validate(
                loginForm[key].value,
                loginForm[key].rules
            );

            newState[key].valid = validationObj.valid;
            newState[key].errMsg = validationObj.msg;

            if (!validationObj.valid) {
                formIsValid = false;
            }
        }
        setLoginForm(newState);
        setFirstTry(false);
        if (!formIsValid) {
            return;
        }
        console.log("Form Submited!");
    };

    const showError = (field) => {
        return !firstTry && !loginForm[field].valid;
    };

    const signIn = async (method) => {
        let user;
        switch (method) {
            case "facebook":
                user = await signInWithFacebook();
                break;
            case "google":
                user = await signInWithGoogle();
                break;
            default:
                break;
        }
        if (user) {
            dispatch(Actions.removePopup());
            dispatch(
                Actions.addNotification({
                    title: "Welcome, " + user.displayName,
                    text: "You've signed in successfully!",
                    status: "success",
                    duration: 3000,
                })
            );
        } else {
            dispatch(
                Actions.addNotification({
                    title: "Error:",
                    text: "Something went wrong, check your details and try again!",
                    status: "error",
                    duration: 3000,
                })
            );
        }
    };

    return (
        <BasePopup icon={<IoIosLogIn />} title="Login" onClose={onClose}>
            <div className="main-wrapper">
                <div className="popup-login-form-wrapper">
                    <div className="logo-wrapper">
                        <img
                            src={Logo}
                            alt="Benny Kerido Photography logo"
                            className="logo-image"
                        />
                        <div className="logo-text-wrapper">
                            <div className="main-line">Benny Kerido</div>
                            <div className="sub-line">Photography</div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="login-form">
                        <FloatInput
                            label="Email Address"
                            type="text"
                            name="email"
                            className="popup-input"
                            showError={showError("email")}
                            onChange={onChange}
                            value={loginForm.email.value}
                            isValid={loginForm.email.valid}
                            errMsg={loginForm.email.errMsg}
                            require
                        />
                        <FloatInput
                            label="Password"
                            type="password"
                            name="password"
                            className="popup-input"
                            showError={showError("password")}
                            onChange={onChange}
                            value={loginForm.password.value}
                            isValid={loginForm.password.valid}
                            errMsg={loginForm.password.errMsg}
                            required
                        />
                        <div className="forgot-password">Forgot password?</div>
                        <Button text="Log in" className="log-in-method" />
                    </form>
                    <hr className="yellow-line" />
                    <Button
                        className="log-in-method facebook"
                        text="Log in With Facebook"
                        icon={<IoLogoFacebook />}
                        iconType="component"
                        onClick={signIn.bind(this, "facebook")}
                    />
                    <Button
                        className="log-in-method google"
                        text="Log in With Google"
                        icon={<IoLogoGoogle />}
                        iconType="component"
                        onClick={signIn.bind(this, "google")}
                    />
                </div>
            </div>
        </BasePopup>
    );
};

export default Popup;
