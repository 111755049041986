import React, { useState } from "react";
import "./index.scss";

const BubbleNavLink = (props) => {
    const [active, setActive] = useState(false);

    const { title, onClick, isActive } = props;
    return (
        <div className={`bubble-link-wrapper`} onClick={onClick}>
            <span className={`title`}>{title}</span>
        </div>
    );
};

export default BubbleNavLink;
