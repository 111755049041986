import React from "react";
import { IoIosLogOut } from "react-icons/io";
import { signOutUser } from "../../../../lib/API/auth";
import Tab from "./components/Tab";
import "./index.scss";

const TabNavigator = (props) => {
    const { screens } = props;

    const handleLogOut = () => {
        signOutUser();
    };

    return (
        <div className={`navigator-wrapper`}>
            <div className="navigator-strip">
                {screens.map((screen) => (
                    <Tab
                        key={screen.id}
                        text={screen.text}
                        path={screen.path}
                        icon={screen.icon}
                    />
                ))}
                <Tab
                    text={"Log out"}
                    onClick={handleLogOut}
                    icon={<IoIosLogOut />}
                    className="logout-area"
                />
            </div>
        </div>
    );
};

export default TabNavigator;
