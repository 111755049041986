import React, { useEffect, useState, useRef } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Table from "../../../../components/Table";
import Cell from "../../../../components/Table/Cell";
import { getContacts, removeContact } from "../../../../lib/API/db";
import Actions from "../../../../redux/actions";
import * as POPUP_TYPES from "../../../../constants/popup-types";
import "./index.scss";

const tableHeader = [
    {
        text: "Time",
        width: "13",
    },
    {
        text: "Name",
        width: "15",
    },
    {
        text: "Email Address",
        width: "20",
    },
    {
        text: "Message",
        width: "47",
    },
    {
        text: "",
        width: "5",
    },
];

const ContactManager = (props) => {
    const [data, setData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
        dispatch(Actions.setPageName("Contacts"));
    }, []);

    const fetchData = async () => {
        const data = await getContacts();
        setData(data);
    };

    const handleDelete = async (id) => {
        const res = await removeContact(id);
        if (res) {
            dispatch(
                Actions.addNotification({
                    title: "Entry removed",
                    text:
                        "Entry from " +
                        data[id].name +
                        " removed successfully!",
                    duration: 3000,
                })
            );
            fetchData();
        } else {
            dispatch(
                Actions.addNotification({
                    status: "error",
                    title: "Error",
                    text: "Something went wrong, please try again...",
                    duration: 3000,
                })
            );
        }
    };

    const popupHandler = (key) => {
        dispatch(
            Actions.addPopup({
                type: POPUP_TYPES.DEFAULT,
                payload: {
                    title: data[key].name,
                    payload: data[key].msg,
                },
            })
        );
    };

    return (
        <div className="contact-manager-wrapper">
            {data && (
                <Table header={tableHeader}>
                    {Object.keys(data).map((key, idx) => {
                        let formattedDate = new Date(data[key].dateSubmitted)
                            .toISOString()
                            .split("T");
                        formattedDate =
                            formattedDate[0] +
                            " " +
                            formattedDate[1].substring(0, 5);
                        const evenClass = (idx + 1) % 2 === 0 ? "even" : "";
                        return (
                            <div key={key} className={`table-row ${evenClass}`}>
                                <Cell
                                    width="13"
                                    data={formattedDate}
                                    onPopup={popupHandler.bind(this, key)}
                                />
                                <Cell
                                    width="15"
                                    data={data[key].name}
                                    onPopup={popupHandler.bind(this, key)}
                                />
                                <Cell
                                    width="20"
                                    data={data[key].email}
                                    onPopup={popupHandler.bind(this, key)}
                                />
                                <Cell
                                    width="47"
                                    data={data[key].msg}
                                    onPopup={popupHandler.bind(this, key)}
                                />
                                <Cell
                                    width="5"
                                    data={
                                        <IoTrashOutline
                                            onClick={handleDelete.bind(
                                                this,
                                                key
                                            )}
                                            className="trash-bin"
                                        />
                                    }
                                    onPopup={popupHandler.bind(this, key)}
                                />
                            </div>
                        );
                    })}
                </Table>
            )}
            {!data && <LoadingIndicator />}
        </div>
    );
};

export default ContactManager;
