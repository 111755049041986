import React, { useEffect, useRef, useState } from "react";
import BasePopup from "../base-popup";
import FloatInput from "../../../components/FloatInput";
import { AiOutlineTag } from "react-icons/ai";
import "./index.scss";
import Button from "../../../components/Button";
import { capitalize, generateUniqueKey, Validate } from "../../../utilites";
import { addTag } from "../../../lib/API/db";
import { useDispatch } from "react-redux";
import Actions from "../../../redux/actions";

const AddTag = (props) => {
    const dispatch = useDispatch();
    const formRef = useRef();
    const { onClose } = props;

    useEffect(() => {
        formRef.current?.focus();
    }, [formRef]);

    const [tag, setTag] = useState({
        valid: false,
        value: "",
        errMsg: "",
        rules: ["required"],
    });
    const [firstTry, setFirstTry] = useState(true);
    const [disable, setDisable] = useState(false);

    const changeHandler = (e) => {
        let value = e.target.value;
        let validationObj = Validate(value, tag.rules);
        let updatedTag = { ...tag };
        updatedTag.valid = validationObj.valid;
        updatedTag.errMsg = validationObj.msg;
        updatedTag.value = value;

        setTag(updatedTag);
    };

    const addHandler = async (e) => {
        e.preventDefault();
        setFirstTry(false);
        let validationObj = Validate(tag.value, tag.rules);
        let updatedTag = { ...tag };
        updatedTag.valid = validationObj.valid;
        updatedTag.errMsg = validationObj.msg;

        setTag(updatedTag);
        if (!validationObj.valid) {
            return;
        }
        updatedTag.value = capitalize(tag.value);
        setDisable(true);
        const status = await addTag(updatedTag.value);
        if (status) {
            dispatch(
                Actions.addNotification({
                    title: "Added",
                    text: `Tag "${updatedTag.value}" added successfully!`,
                    status: "success",
                    duration: 3000,
                })
            );
            dispatch(Actions.removePopup());
            dispatch(Actions.reloadData());
        } else {
            dispatch(
                Actions.addNotification({
                    title: "Error",
                    text: `Something went wrong, please try again...`,
                    status: "error",
                    duration: 3000,
                })
            );
            setDisable(false);
        }
    };

    const showError = () => {
        return !firstTry && !tag.valid;
    };

    return (
        <BasePopup
            title="Add a Tag"
            icon={<AiOutlineTag />}
            onClose={onClose}
            myRef={formRef}
        >
            <form onSubmit={addHandler}>
                <FloatInput
                    label="Insert new tag"
                    value={tag.value}
                    isValid={tag.valid}
                    errMsg={tag.errMsg}
                    showError={showError()}
                    onChange={changeHandler}
                    disabled={disable}
                    required
                />
                <div className="buttons-wrapper">
                    <Button text="Add" disabled={disable} />
                    <Button
                        text="Cancel"
                        className="cancel-btn"
                        onClick={onClose}
                        disabled={disable}
                    />
                </div>
            </form>
        </BasePopup>
    );
};

export default AddTag;
