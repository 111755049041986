import React from 'react';
import './index.scss';

const EditableHeadline = (props) => {
    const { className, onChange, data, placeholder } = props;
    return (
    <div className='editable-headline-wrapper'>
        <input type="text" className="input" value={data} onChange={onChange} placeholder={placeholder} />
    </div>);
}

export default EditableHeadline;