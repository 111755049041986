import React from 'react';
import './index.scss';

const ToolbarButton = (props) => {
    const { 
        icon,
        description = "",
        onClick = () => {}
    } = props;
    return (
    <div className='toolbar-button-wrapper' onClick={onClick}>
        {icon}
        <span className="description">
            {description}
        </span>
    </div>);
}

export default ToolbarButton;