import React, { useState } from 'react';
import './index.scss';

const AddOverlay = (props) => {
    const { onSubmit, windowTitle, placeholder, icon = <></> } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState();

    const localOnChange = (e) => { 
        setValue(e.target.value)
    }

    const onCancel = () => { 
        setIsOpen(false);
    }

    const onOpen = () => { 
        setIsOpen(true);
    }

    return (
    <div className='editable-overlay-wrapper'>
        <div className="backdrop" onClick={onOpen}>{icon}</div>
        {isOpen && (<div className="popup">
            <div className="popup-title">
                {windowTitle}
            </div>
            <input 
                type="text" 
                className="text-input" 
                placeholder={placeholder}
                onChange={localOnChange}
            />
            <div className="btns-wrapper">
                <button className='btn' onClick={onSubmit.bind(this, value)}>
                    Save
                </button>
                <button className='btn' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>)}
    </div>);
}

export default AddOverlay;