export const generateUniqueKey = (num: number) => {
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let res = [];
    for (let i = 0; i < num; i++) {
        let index = Math.random() * characters.length;
        res.push(characters.charAt(index));
    }
    return res.join("");
};

export function Validate(value, rules) {
    const Validations = {
        no_validation: {
            valid: (val) => true,
            msg: "",
        },
        required: {
            valid: (val) => val !== "" && val !== undefined,
            msg: "This field is required!",
        },
        address: {
            valid: (val) => val !== "" && val !== undefined,
            msg: "You must choose an address",
        },
        email: {
            valid: (val) =>
                /^([\w!#$%&'*+-/=?^`{|}~]+\.)*[\w!#$%&'*+-/=?^`{|}~]+@((((([a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]{1})|[a-zA-Z])\.)+[a-zA-Z]{2,6})|(\d{1,3}\.){3}\d{1,3}(:\d{1,5})?)$/.test(
                    val
                ),
            msg: "Invalid email address",
        },
        full_name: {
            valid: (val) =>
                /^([a-z | A-Z]{2,})+\s+([a-z | A-Z]{2,})+$/.test(val),
            msg: "Please insert your full name",
        },
        checkbox: {
            valid: (val) => val,
            msg: "This field is required!",
        },
        required_select: {
            valid: (val) => {
                if (val === undefined) return false;
                return val !== -1;
            },
            msg: "This field is required!",
        },
    };

    let valid = true;
    let msg = "";

    for (let rule of rules) {
        if (typeof rule === "function") {
            valid = rule();
            msg = "This field is required!";
        } else {
            if (!Validations[rule].valid(value)) {
                valid = false;
                msg = Validations[rule].msg;
                break;
            }
        }
    }

    return { valid: valid, msg: msg };
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
