import React, { useState, useRef, useEffect } from "react";
import errorIcon from "../../assets/icons/error-icon.png";
import "./index.scss";

const FloatInput = (props) => {
    const {
        label,
        value,
        onChange,
        isValid = true,
        errMsg,
        type = "text",
        name,
        autoComplete = "off",
        maxLength,
        showError = false,
        className = "",
        required = false,
        disabled = false,
    } = props;

    const [isFirstTime, setIsFirstTime] = useState(true);
    const [errorVisible, setErrorVisible] = useState(showError);
    const [isFocused, setIsFocused] = useState(false);
    const [labelSize, setLabel] = useState({
        width: 0,
        height: 0,
    });
    const inputRef = useRef();
    const labelRef = useRef();

    useEffect(() => {
        if (value !== "" && value !== undefined) {
            setIsFocused(true);
        } else {
            if (document.activeElement !== inputRef.current) {
                setIsFocused(false);
            }
        }
    }, [value]);

    useEffect(() => {
        let fontSize = getComputedStyle(labelRef.current).fontSize;
        fontSize = parseFloat(fontSize);

        setLabel({
            width: 12 * 0.6 * (label.length + 2),
            height: fontSize,
        });
    }, []);

    useEffect(() => {
        setErrorVisible(showError);
    }, [showError]);

    const onBlur = () => {
        if (
            inputRef.current.value === "" ||
            inputRef.current.value === undefined
        ) {
            setIsFocused(false);
        }
    };

    const onLabelClick = () => {
        inputRef.current.focus();
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    const onValueChange = (e) => {
        setErrorVisible(showError);
        onChange(e);
    };

    const floatClass = isFocused ? "active" : "";
    const errorClass = errorVisible ? "error" : "";
    return (
        <div className={"float-input-wrapper " + className}>
            <span
                className={`float-label-background ${floatClass}`}
                style={{
                    width: labelSize.width - 2,
                    height: labelSize.height - 2,
                }}
            ></span>
            <span
                ref={labelRef}
                className={`float-label ${floatClass}`}
                onClick={onLabelClick}
            >
                {label}
                {required && <span className="required-indicator">*</span>}
            </span>
            <div className="input-wrapper">
                <input
                    ref={inputRef}
                    className={"float-input " + errorClass}
                    type={type}
                    autoComplete={autoComplete}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    name={name}
                    onChange={onValueChange}
                    value={value}
                    maxLength={maxLength}
                    disabled={disabled}
                />
                <img
                    src={errorIcon}
                    alt="Error Icon"
                    className={
                        "float-error-icon " + (errorVisible ? "show" : "")
                    }
                />
            </div>
            {errorVisible && (
                <div className={`error-wrapper`}>
                    <div className="arrow-left"></div>
                    <div className={`float-error`}>{errMsg}</div>
                </div>
            )}
        </div>
    );
};

export default FloatInput;
