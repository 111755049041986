import React, { useEffect, useState, useRef } from "react";
import "./index.scss";

const ContactDetails = (props) => {
    const { icon, text } = props;
    return (
        <div className="contact-info-wrapper">
            <div className="contact-icon">{icon}</div>
            <div className="contact-info">
                <span className="contact-info-text">{text}</span>
            </div>
        </div>
    );
};

export default ContactDetails;
