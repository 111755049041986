import React from "react";
import { useSelector } from "react-redux";
import Notification from "./Notification";
import "./index.scss";

const Notifications = (props) => {
    const notifications = useSelector((store) => store.notifications);

    const getNotifications = () => {
        let notificationsToReturn = notifications.map((notify) => (
            <Notification id={notify.id} key={notify.id} payload={notify} />
        ));
        return notificationsToReturn;
    };

    return <div className="notifications-wrapper">{getNotifications()}</div>;
};

export default Notifications;
