import {
    getDoc,
    getDocs,
    doc,
    getFirestore,
    updateDoc,
    deleteField,
    addDoc,
    collection,
    query,
    where,
    orderBy,
    setDoc,
    deleteDoc,
} from "firebase/firestore";
import app from "../app";
const db = getFirestore(app);
const ref = {
    tags: doc(db, "photos", "tags"),
    contacts: collection(db, "contact"),
};
export const getTags = async () => {
    const docSnap = await getDoc(ref.tags);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.log("No document found");
    }
};

export const removeTag = async (tag) => {
    try {
        await updateDoc(ref.tags, {
            [tag]: deleteField(),
        });
    } catch (e) {
        console.log(e);
    }
};

export const addTag = async (tag) => {
    try {
        await updateDoc(ref.tags, {
            [tag.toLowerCase()]: tag,
        });
        return true;
    } catch (e) {
        return false;
    }
};

export const postContact = async (name, email, msg) => {
    let time = new Date().getTime();
    const res = await addDoc(ref.contacts, {
        dateSubmitted: time,
        name: name,
        email: email,
        msg: msg,
    });
    if (res.id) {
        return true;
    }
    return false;
};

export const getContacts = async () => {
    const q = query(ref.contacts, orderBy("dateSubmitted", "desc"));
    const querySnapshot = await getDocs(q);
    if (querySnapshot) {
        let dataToReturn = {};
        querySnapshot.forEach((doc) => {
            dataToReturn[doc.id] = doc.data();
        });
        return dataToReturn;
    }
};

export const removeContact = async (id) => {
    const ref = doc(db, "contact", id);
    try {
        await deleteDoc(ref);
        return true;
    } catch (e) {
        return false;
    }
};
