import React from "react";
import "./index.scss";

const LoadingIndicator = (props) => {
    return (
        <div className="loading-wrapper">
            <div className="lds-ripple">
                <div></div>
                <div></div>
            </div>
            <h2 className="text">Loading</h2>
        </div>
    );
};

export default LoadingIndicator;
