import React, { useState } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { BiImageAdd } from 'react-icons/bi';
import AddOverlay from '../add-overlay';
import './index.scss';


const EditableImage = (props) => {
    const { value, alt, className, placeholder, onChange, type } = props;

    const resetSrc = () => { 
        onChange(null);
    }
    return (
    <div className={`editable-image-wrapper ${className}`}>
        <div className="image-wrapper">
            {value && (
            <>
                <img src={value} alt={alt} />
                <div className="remove-btn" onClick={resetSrc}> 
                    <BsTrashFill /> 
                </div>
            </>
            )}
            {!value && (
                <AddOverlay
                    onSubmit={onChange}
                    icon={<BiImageAdd />}
                    windowTitle="Insert Image Link"
                    placeholder="Image Link"
                />
            )}
        </div>
        {/* <input type="text" className="link-input" placeholder={placeholder} onChange={onChange} /> */}
    </div>);
}

export default EditableImage;