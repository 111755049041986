import { formsReducers } from "../slices/forms";
import { stateReducers } from "../slices/states";
import { popupReducers } from "../slices/popups";

let Reducers = {};

for (let reducer in formsReducers) {
    Reducers[reducer] = formsReducers[reducer];
}

for (let reducer in popupReducers) {
    Reducers[reducer] = popupReducers[reducer];
}

for (let reducer in stateReducers) {
    Reducers[reducer] = stateReducers[reducer];
}

export default Reducers;
