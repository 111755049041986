import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Actions from "../../../../redux/actions";
import "./index.scss";

const RemovePhoto = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Actions.setPageName("Remove an Image"));
    }, []);
    return <div className="remove-photo-wrapper"></div>;
};

export default RemovePhoto;
