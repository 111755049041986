import React, { useState } from 'react';
import { FcAddRow, FcApproval } from 'react-icons/fc';
import EditableParagraph from './components/editable-paragraph';
import ToolbarButton from './components/toolbar-btn';
import * as PARAGRAPH_TYPES from '../constants/paragraph-types';
import './index.scss';

const mockData = [
    {
        key: "Hello World 1",
        type: PARAGRAPH_TYPES.VIDEO,
        value: "https://www.youtube.com/embed/BZqzhmlTkAc",
        placeholder: "Enter video link"
    },
    {
        key: "Hello World 2",
        type: PARAGRAPH_TYPES.IMAGE,
        value: "https://i.ytimg.com/vi/fSQfNRMWSmA/maxresdefault.jpg",
        placeholder: "Enter image link"
    },
]

const Editor = (props) => {
    const { 
        titleClassName = "",
        subtitleClassName = "",
        authorClassName = "",
        paragraphClassName = {
            headline: '',
            paragraph: '',
            image: '',
            video: '',
        },
        onSubmit = () => {},
        content = { paragraphs: {
            0: mockData[0],
            1: mockData[1]
        } }
     } = props;

    const [localContent, setLocalContent] = useState(content);

    const onParagraphChange = (id, data) => { 
        console.log(id, data)
        let updatedParagraphs = { ...localContent };
        updatedParagraphs.paragraphs[id].value = data;
        console.log(JSON.stringify(updatedParagraphs))
        setLocalContent(updatedParagraphs)
    }

    return (
    <div className='editor-wrapper'>
        <div className="toolbar-wrapper">
            <ToolbarButton
                icon={<FcAddRow/>}
                description="Add section"
            />
            <ToolbarButton
                icon={<FcApproval />}
                description="Save"
            />
        </div>
        <div className="fields-wrapper">
            <input type="text" placeholder='Title' className={`title ${titleClassName}`}/>
            <input type="text" placeholder='Sub title' className={`subtitle ${subtitleClassName}`} />
            <input type="text" placeholder='Author' className={`author ${authorClassName}`} />
            {Object.keys(content.paragraphs).map((para, idx) => (
                <EditableParagraph
                    id={idx}
                    para={content.paragraphs[idx]}
                    onChange={onParagraphChange}
                    key={"editable-paragraph#" + idx}
                    classNames={paragraphClassName}
                />
            ))}
        </div>

    </div>);
}

export default Editor;