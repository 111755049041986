import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Routes from "../../../constants/routes";

const DocumentTitle = () => {
    const location = useLocation();
    useEffect(() => {
        if (Routes[location.pathname]) {
            document.title =
                Routes[location.pathname] + " | " + process.env.REACT_APP_TITLE;
        } else {
            document.title = "" + process.env.REACT_APP_TITLE;
        }
    }, [location.pathname]);
    return <></>;
};

export default DocumentTitle;
