import React from "react";
import { NavLink } from "react-router-dom";
import ImageGrid from "../../../components/ImageGrid";
import "./index.scss";

const Gallery = (props) => {
    return (
        <div className="galley-wrapper">
            <ImageGrid />
            <NavLink to={"/"}>Click here to see more</NavLink>
        </div>
    );
};

export default Gallery;
