import React, { useEffect, useState, useRef } from "react";
import "./index.scss";

const Button = (props) => {
    const {
        text,
        icon,
        onClick = () => {},
        iconType,
        className = "",
        disabled = false,
    } = props;

    const disableClass = disabled ? "disabled" : "";
    return (
        <button
            className={`button-wrapper ${className} ${disableClass}`}
            onClick={disabled ? () => {} : onClick}
        >
            <span className="button-text">{text}</span>
            {icon &&
                (iconType === "image" ? (
                    <img src={icon} alt="button icon" className="button-icon" />
                ) : (
                    icon
                ))}
        </button>
    );
};

export default Button;
