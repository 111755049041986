import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithCredential,
    setPersistence,
    inMemoryPersistence,
} from "firebase/auth";
import app from "../app";

const googleAuth = new GoogleAuthProvider();
const facebookAuth = new FacebookAuthProvider();

const auth = getAuth(app);
export default auth.currentUser;

export const signInWithGoogle = async () => {
    await setPersistence(auth, inMemoryPersistence);
    const response = await signInWithPopup(auth, googleAuth);
    if (response) {
        const user = response.user;
        const credential = GoogleAuthProvider.credentialFromResult(response);
        const dataToStore = {
            it: credential.idToken,
            at: credential.accessToken,
        };
        localStorage.setItem("@at", JSON.stringify(dataToStore));
        return convertToUser(user);
    }
    return null;
};

export const signInWithFacebook = async () => {
    let res = await signInWithPopup(auth, facebookAuth);
    if (res) {
        const user = res.user;

        const credential = FacebookAuthProvider.credentialFromResult(res);
        const dataToStore = {
            it: credential.idToken,
            at: credential.accessToken,
        };
        localStorage.setItem("@at", JSON.stringify(dataToStore));
        const userData = convertToUser(user);
        return userData;
    }
};

export const convertToUser = (data) => {
    const { uid, displayName, email, photoURL } = data;
    const userData = {
        uid: uid,
        displayName: displayName,
        email: email,
        photoURL: photoURL,
    };
    return userData;
};

export const checkIfUserIsLogged = async () => {
    let cache = localStorage.getItem("@at");
    if (cache) {
        cache = JSON.parse(cache);
        const { it, at } = cache;
        const credential = GoogleAuthProvider.credential(it, at);
        try {
            await signInWithCredential(auth, credential);
        } catch (e) {
            console.log();
        }
    }
    return null;
};

export const signOutUser = async () => {
    await auth.signOut();
    localStorage.removeItem("@at");
};
