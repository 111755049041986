import { createSlice } from "@reduxjs/toolkit";
import { generateUniqueKey } from "../../../utilites";

let Slices = [];
let popupActions = {};
let popupReducers = {};

const popupSlice = createSlice({
    name: "popupsArray",
    initialState: [],
    reducers: {
        addPopup: (state, action) => {
            state = state.push(action.payload);
        },
        removePopup: (state) => {
            state = state.pop();
        },
    },
});

Slices.push(popupSlice);

const notificationSlice = createSlice({
    name: "notifications",
    initialState: [],
    reducers: {
        addNotification: (state, action) => {
            state = state.push({ ...action.payload, id: generateUniqueKey(2) });
        },
        removeNotification: (state, action) => {
            return state.filter((item) => item.id !== action.payload);
        },
    },
});

Slices.push(notificationSlice);

for (let Slice of Slices) {
    popupActions = { ...popupActions, ...Slice.actions };
    let reducer = { [Slice.name]: Slice.reducer };
    popupReducers = { ...popupReducers, ...reducer };
}

export { popupActions };
export { popupReducers };
