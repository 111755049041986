import React from "react";
import { MdOutlineCopyright } from "react-icons/md";
import {
    AiOutlineInstagram,
    AiOutlineFacebook,
    AiOutlineWhatsApp,
} from "react-icons/ai";
import "./index.scss";
import Logo from "../../../assets/logo.png";
import { sendTo } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../redux/actions";
import * as POPUP_TYPES from "../../../constants/popup-types";
import { NavLink } from "react-router-dom";

const Footer = () => {
    const dispatch = useDispatch();
    const userData = useSelector((store) => store.userData);
    const loginPopupHandler = () => {
        dispatch(Actions.addPopup({ type: POPUP_TYPES.LOGIN }));
    };

    return (
            <div className="footer-wrapper noselect">
                <div className="logo-wrapper">
                    <img
                        src={Logo}
                        alt="Benny Kerido Photography logo"
                        className="logo-image"
                    />
                    <div className="logo-text-wrapper">
                        <div className="main-line">Benny Kerido</div>
                        <div className="sub-line">Photography</div>
                    </div>
                </div>
                <div className="socialmedia-wrapper">
                    <div className="socialmedia-item">
                        <AiOutlineInstagram
                            onClick={sendTo.bind(this, "instagram")}
                        />
                    </div>
                    <div className="socialmedia-item">
                        <AiOutlineFacebook
                            onClick={sendTo.bind(this, "facebook")}
                        />
                    </div>
                    <div className="socialmedia-item">
                        <AiOutlineWhatsApp
                            onClick={sendTo.bind(this, "whatsapp")}
                        />
                    </div>
                </div>
                {userData.uid && (
                    <NavLink to={"/dashboard"} className="login-section">
                        Go to Dashboard
                    </NavLink>
                )}
                {!userData.uid && (
                    <div className="login-section" onClick={loginPopupHandler}>
                        Sign in
                    </div>
                )}
                <div className="copyrights">
                    <MdOutlineCopyright /> 2022 - 2022 www.bennykerido.com - All
                    Rights Reserved.
                </div>
                <div className="terms-and-privacy">
                    <NavLink
                        to={"/docs/terms-of-use"}
                        className="login-section"
                    >
                        Terms of Use
                    </NavLink>
                    <span className="seperator">•</span>
                    <NavLink
                        to={"/docs/privacy-policy"}
                        className="login-section"
                    >
                        Privacy Policy
                    </NavLink>
                </div>
            </div>
    );
};

export default Footer;
