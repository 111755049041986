import { useEffect, useState } from "react";
import * as WIDTHS from '../constants/window-widths';

const states = {
    0: { type: "desktop", text: "desktop_max" },
    1: { type: "desktop", text: "desktop_large" },
    2: { type: "desktop", text: "desktop" },
    3: { type: "mobile", text: "tablet" },
    4: { type: "mobile", text: "mobile" },
};

const useDeviceState = () => {
    const [state, setState] = useState(states[0]);
    
    useEffect(() => {
        function getZindex(e) {
            const index = getState(e.target ? e.target.innerWidth:e);
            setState(states[index]);
        }
        getZindex(window.innerWidth);
        window.addEventListener("resize", getZindex);
        return () => {
            window.removeEventListener('resize', getZindex);
        }
    }, []);

    return state;
}
export default useDeviceState;

const getState = (width) => {
    if(width >= WIDTHS.MOBILE && width < WIDTHS.TABLET){ return 4; } 
    else if(width >= WIDTHS.TABLET && width < WIDTHS.DESKTOP){ return 3; } 
    else if(width >= WIDTHS.DESKTOP && width < WIDTHS.DESKTOP_LARGE){ return 2; } 
    else if(width >= WIDTHS.DESKTOP_LARGE && width < WIDTHS.DESKTOP_MAX){ return 1; } 
    else if(width >= WIDTHS.DESKTOP_MAX){ return 0; }
    return 4;
}
