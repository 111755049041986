import React, { useEffect, useRef, useState } from "react";

import { CgMenuLeft, CgMenuRight } from "react-icons/cg";
import { AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";

import "./index.scss";
import Bubble from "../Bubble";
import { sendTo } from "../../App";
import { NavLink, useLocation } from "react-router-dom";
import useDeviceState from "../../hook/useDeviceState";
import DrawerNavigator from "../DrawerNavigator";
import LogoWhite from '../../assets/LogoWhite.svg';
import HorizontalScrollIndicator from "../HorizontalScrollIndicator";

const Header = () => {
    const headerRef = useRef();
    const [openMenu, setOpenMenu] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();
    const deviceState = useDeviceState();

    useEffect(() => {
        const scrollHandler = (e) => {
          const scroll = window.scrollY;
          const headerHeight = headerRef.current?.clientHeight;
          if(scroll > headerHeight){
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        };
        document.addEventListener('scroll', scrollHandler);
        return () => {
            document.removeEventListener('scroll', scrollHandler);
        }
    }, []);

    const menuToggle = () => {
        setOpenMenu((prev) => !prev);
    };

    const handleMenuClick = (state) => {
        setOpenMenu(state);
    };

    const scrollToSection = (id) => {
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        );
        window.scrollTo({
            top: id * vh,
            behavior: "smooth",
        });
    };

    const scrollClassName = scrolled ? 'scrolled':'';
    return (
        <div className={`header-wrapper ${scrollClassName}`} ref={headerRef}>
            <div className="header-content-wrapper">
                {deviceState.type === 'mobile' && (
                    <div className="mobilefix">
                        <CgMenuLeft/>
                    </div>
                )}
                <div className="logo-wrapper noselect">
                    <NavLink to={'/'} className={`logo-img-wrapper link-styling-remove ${scrolled ? '':'hide'}`}>
                        <img alt="benny kerido photography logo" src={LogoWhite} />
                    </NavLink>
                    <NavLink to={"/"} className={`logo-text-wrapper link-styling-remove ${scrolled ? 'hide':''}`}>
                        <div className="logo-text">
                            <div className="main-line">Benny Kerido</div>
                            <div className="sub-line">Photography</div>
                        </div>
                    </NavLink>
                </div>
                <div className="menu-icon">
                    <AiOutlineInstagram
                        className={`icon ${scrolled ? 'hide':''}`}
                        onClick={sendTo.bind(this, "instagram")}
                    />
                    <AiOutlineFacebook
                        className={`icon ${scrolled ? 'hide':''}`}
                        onClick={sendTo.bind(this, "facebook")}
                    />
                    {location.pathname === "/" && (
                        <>
                            
                            {deviceState.type === 'desktop' && (<>
                                <CgMenuRight className="icon" onClick={menuToggle} />
                                <Bubble
                                    onBlur={handleMenuClick}
                                    onClick={scrollToSection}
                                    isOpen={openMenu}
                                />
                            </>)}
                            {deviceState.type === 'mobile' && (<>
                                <CgMenuLeft className="icon" onClick={menuToggle} />
                                <DrawerNavigator 
                                    onBlur={handleMenuClick.bind(this, false)}
                                    onClick={scrollToSection} 
                                    isOpen={openMenu}
                                />
                            </>)}
                        </>
                    )}
                </div>
            </div>
            <HorizontalScrollIndicator />
        </div>
    );
};

export default Header;
