import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BiErrorCircle } from "react-icons/bi";
import "./index.scss";
import {
    AiOutlineCheckCircle,
    AiOutlineInfoCircle,
    AiOutlineWarning,
} from "react-icons/ai";
import Actions from "../../../redux/actions";

const TYPES = {
    warning: {
        color: "#fa9f47",
        icon: <AiOutlineWarning />,
    },
    error: {
        color: "#d64242",
        icon: <BiErrorCircle />,
    },
    info: {
        color: "#0090e0",
        icon: <AiOutlineInfoCircle />,
    },
    success: {
        color: "#34c240",
        icon: <AiOutlineCheckCircle />,
    },
};
const FADE_IN = 100;

const Notification = (props) => {
    const { payload, id } = props;
    const { title, text, status = "success", duration = 5000 } = payload;

    const dispatch = useDispatch();
    const [animate, setAnimate] = useState(false);
    const [miliseconds, setMiliseconds] = useState(0);

    const [intervalId, setIntervalId] = useState(null);

    const handleStartTimer = () => {
        setIntervalId(
            setInterval(() => {
                setMiliseconds((prev) => {
                    if (prev < duration) {
                        return prev + 1;
                    }

                    clearInterval(id);
                    return prev;
                });
            }, 1)
        );
    };

    const handlePauseTimer = () => {
        clearInterval(intervalId);
    };

    const onCloseNotification = () => {
        handlePauseTimer();
        setAnimate(false);
        setTimeout(() => {
            dispatch(Actions.removeNotification(id));
        }, 800);
    };

    useEffect(() => {
        if (duration === miliseconds) {
            onCloseNotification();
        }
    }, [miliseconds]);

    useEffect(() => {
        handleStartTimer();
        setTimeout(() => {
            setAnimate(true);
        }, FADE_IN);
    }, []);

    const slideIn = animate ? "slide" : "";
    return (
        <div
            className={`notification-wrapper ${slideIn}`}
            style={{ backgroundColor: TYPES[status].color }}
            onMouseEnter={handlePauseTimer}
            onMouseLeave={handleStartTimer}
        >
            <div className="icon-wrapper">{TYPES[status].icon}</div>
            <div className="title-text-wrapper">
                <div className="notification-title">{title}</div>
                <div className="notification-text">{text}</div>
            </div>
        </div>
    );
};

export default Notification;
