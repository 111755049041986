import React from "react";
import { Outlet } from "react-router-dom";
import "./index.scss";

const Document = () => {
    return (
        <div className="document-wrapper">
            <Outlet />
        </div>
    );
};

export default Document;
