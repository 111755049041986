import React from "react";
import "./index.scss";
import { IoIosArrowDown } from "react-icons/io";
import Logo from "../../../assets/logo coloured.png";
import { useDispatch } from "react-redux";
import Actions from "../../../redux/actions";

const TYPES = ["success", "error", "warning", "info"];

const Splash = (props) => {
    const { id } = props;
    const dispatch = useDispatch();

    // const handleClick = () => {
    //     let type = TYPES[Math.floor(Math.random() * TYPES.length)];

    //     dispatch(
    //         Actions.addNotification({
    //             status: type,
    //             title: "DUMMY NOTIFICATION",
    //             text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //             duration: 1000,
    //         })
    //     );
    // };

    return (
        <div className="splash-wrapper" id={id}>
            <img
                src={Logo}
                alt="Benny Kerido Photography logo"
                className="logo-image"
            />
            <div className="text">Welcome to my portfolio!</div>
            <div className="text">Under construction :)</div>
            <div className="icon">
                <IoIosArrowDown />
            </div>
        </div>
    );
};

export default Splash;
