import React from "react";
import { generateUniqueKey } from "../../utilites";
import { IoTrashOutline } from "react-icons/io5";
import "./index.scss";
import Checkbox from "../Checkbox";
import Cell from "./Cell";

const Table = (props) => {
    const {
        header = [],
        rows = [],
        onSelect = () => {},
        selected = [],
        onRemove = () => {},
        children,
    } = props;
    return (
        <div className="table-wrapper">
            <div className="table-header-wrapper">
                {header.map((cell) => (
                    <Cell
                        width={cell.width}
                        key={generateUniqueKey(3)}
                        isHeader
                        data={cell.text}
                    />
                ))}
            </div>
            {children}
            {rows.length === 0 && !children && (
                <span className="no-result-msg">No results found</span>
            )}
        </div>
    );
};

export default Table;
