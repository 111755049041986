import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import FloatInput from "../../../components/FloatInput";
import FloatTextArea from "../../../components/FloatTextArea";

import Actions from "../../../redux/actions";
import { BiPaperPlane } from "react-icons/bi";
import { MdAlternateEmail, MdPhone, MdLocationPin } from "react-icons/md";

import ContactImg from "../../../assets/images/contact-img.jpg";

import "./index.scss";
import ContactDetails from "../../../components/ContactDetails";
import { Validate } from "../../../utilites";
import { postContact } from "../../../lib/API/db";

const getFormInitialState = () => {
    return {
        full_name: {
            isValid: false,
            errMsg: "",
            rules: ["full_name", "required"],
        },
        email: {
            isValid: false,
            errMsg: "",
            rules: ["email", "required"],
        },
        msg: {
            isValid: false,
            errMsg: "",
            rules: ["required"],
        },
    };
};

const Contact = (props) => {
    const { id } = props;

    const [disabled, setDisabled] = useState(false);

    const [firstTry, setFirstTry] = useState(true);
    const [formState, setFormState] = useState(getFormInitialState());

    const form = useSelector((store) => store.contactData);
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isFormValid = true;
        let updatedState = { ...formState };
        for (let key of Object.keys(formState)) {
            let validationObj = Validate(form[key], formState[key].rules);
            updatedState[key].isValid = validationObj.valid;
            updatedState[key].errMsg = validationObj.msg;
            if (!validationObj.valid) {
                isFormValid = false;
            }
        }
        setFormState(updatedState);
        setFirstTry(false);
        if (!isFormValid) {
            return;
        }
        setDisabled(true);
        setFormState(getFormInitialState());
        setFirstTry(true);
        const res = await postContact(form.full_name, form.email, form.msg);
        if (res) {
            dispatch(
                Actions.addNotification({
                    title: "We've got it",
                    text: "Contact form has been submitted successfully!",
                    duration: 3000,
                    status: "success",
                })
            );
            setDisabled(false);
            dispatch(
                Actions.addField({
                    full_name: "",
                    email: "",
                    msg: "",
                })
            );
        }
    };

    const onFieldChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let updatedState = { ...formState };
        let validationObj = Validate(value, updatedState[name].rules);
        updatedState[name].isValid = validationObj.valid;
        updatedState[name].errMsg = validationObj.msg;
        setFormState(updatedState);
        dispatch(Actions.addField({ [name]: value }));
    };

    const showError = (field) => {
        return !firstTry && !formState[field].isValid;
    };

    return (
        <div className="contact-wrapper" id={id}>
            <div className="contact-form-wrapper">
                <div className="contact-form">
                    <form onSubmit={handleSubmit}>
                        <h1 className="contact-title">Contact Me</h1>
                        <FloatInput
                            label="Full Name"
                            type="text"
                            name="full_name"
                            value={form.full_name}
                            isValid={formState.full_name.isValid}
                            errMsg={formState.full_name.errMsg}
                            onChange={onFieldChange}
                            showError={showError("full_name")}
                            className="field"
                            disabled={disabled}
                            required
                        />
                        <FloatInput
                            label="Email Address"
                            type="email"
                            name="email"
                            autoComplete={"off"}
                            value={form.email}
                            isValid={formState.email.isValid}
                            errMsg={formState.email.errMsg}
                            onChange={onFieldChange}
                            showError={showError("email")}
                            className="field"
                            disabled={disabled}
                            required
                        />
                        <FloatTextArea
                            label="Your message"
                            name="msg"
                            value={form.msg}
                            isValid={formState.msg.isValid}
                            errMsg={formState.msg.errMsg}
                            onChange={onFieldChange}
                            showError={showError("msg")}
                            className="field"
                            disabled={disabled}
                            required
                        />
                        <Button
                            onClick={handleSubmit}
                            text="Send"
                            icon={<BiPaperPlane className="button-icon" />}
                            iconType="component"
                            disabled={disabled}
                        />
                    </form>
                    <hr style={{ opacity: 0.3, marginTop: 10 }} />
                    <div className="contact-details-wrapper">
                        <h3 className="details-title">Contact Information</h3>
                        <ContactDetails
                            icon={<MdLocationPin />}
                            text={"Ramla, Israel"}
                        />
                        <ContactDetails
                            icon={<MdPhone />}
                            text={"+972 52 524 1194"}
                        />
                        <ContactDetails
                            icon={<MdAlternateEmail />}
                            text={"kerido112@gmail.com"}
                        />
                    </div>
                </div>
            </div>
            <div className="contact-img-container">
                <img src={ContactImg} alt="" className="contact-img" />
            </div>
        </div>
    );
};

export default Contact;
