import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateUniqueKey } from "../utilites";
import * as POPUP_TYPES from "../constants/popup-types";

import DefaultPopup from "./components/base-popup";
import LoginPopup from "./components/login";

import "./index.scss";
import Actions from "../redux/actions";
import { usePrompt } from "../hook/useBlocker-usePrompt";
import AddTag from "./components/add-tag";

const Popups = (props) => {
    const popupsArray = useSelector((store) => store.popupsArray);
    const dispatch = useDispatch();

    // usePrompt("Leave screen? ");

    const getPopupComponent = (key, type, payload) => {
        let popupComponents = {
            [POPUP_TYPES.DEFAULT]: (
                <DefaultPopup
                    key={key}
                    title={payload?.title}
                    payload={payload?.payload}
                    onClose={closePopupOnCloseBtn}
                />
            ),
            [POPUP_TYPES.LOGIN]: (
                <LoginPopup
                    key={key}
                    payload={payload}
                    onClose={closePopupOnCloseBtn}
                />
            ),
            [POPUP_TYPES.ADD_TAG]: (
                <AddTag
                    key={key}
                    payload={payload}
                    onClose={closePopupOnCloseBtn}
                />
            ),
        };

        return type in popupComponents
            ? popupComponents[type]
            : popupComponents[POPUP_TYPES.DEFAULT];
    };

    const renderPopups = () => {
        let popupsToRender = popupsArray.map((popup) => {
            let key = generateUniqueKey(16);
            return getPopupComponent(key, popup.type, popup.payload);
        });
        return popupsToRender;
    };

    const closePopupOnCloseBtn = () => {
        dispatch(Actions.removePopup());
    };
    return (
        <div className={`popup-wrapper`}>
            <Backdrop onClick={closePopupOnCloseBtn} />
            {renderPopups()}
        </div>
    );
};

export default Popups;

const Backdrop = ({ onClick }) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 50);
    }, []);

    const fadeIn = animate ? "fade-in" : "";
    return <div className={`backdrop ${fadeIn}`} onClick={onClick}></div>;
};
