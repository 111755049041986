import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Actions from "../../../../redux/actions";
import "./index.scss";

const AddNew = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Actions.setPageName("Add an Image"));
    }, []);
    return <div className="class-name">New Image</div>;
};

export default AddNew;
