import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IoIosArrowUp } from "react-icons/io";
import "./index.scss";

const BackToTop = () => {
    const scroll = useSelector((store) => store.scroll);
    const { float } = scroll;

    const [scrollUp, setScrollUp] = useState(false);
    const [lastPos, setLastPos] = useState(0);

    useEffect(() => {
        if (float < lastPos && !scrollUp) {
            setScrollUp(true);
        }
        if (float === 0 || float > lastPos) {
            setScrollUp(false);
        }
        setLastPos(float);
    }, [float]);

    const backToTopHanlder = () => {
        setScrollUp(false);
        setLastPos(0.0);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const jumpInClass = scrollUp ? "slide-in" : "";
    return (
        <div
            className={`back-to-top-wrapper ${jumpInClass}`}
            onClick={backToTopHanlder}
        >
            <IoIosArrowUp className="arrow-up" />
        </div>
    );
};

export default BackToTop;
