import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let formsActions = {};
let formsReducers = {};

const contactSlice = createSlice({
    name: "contactData",
    initialState: {},
    reducers: {
        addField: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetFields: () => {
            return {};
        },
    },
});

Slices.push(contactSlice);

for (let Slice of Slices) {
    formsActions = { ...formsActions, ...Slice.actions };
    let reducer = { [Slice.name]: Slice.reducer };
    formsReducers = { ...formsReducers, ...reducer };
}

export { formsActions };
export { formsReducers };
