import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Table from "../../../../components/Table";
import { getTags, removeTag } from "../../../../lib/API/db";
import Actions from "../../../../redux/actions";
import * as POPUP_TYPES from "../../../../constants/popup-types";
import "./index.scss";
import { capitalize, generateUniqueKey } from "../../../../utilites";
import Cell from "../../../../components/Table/Cell";
import Checkbox from "../../../../components/Checkbox";
import { IoTrashOutline } from "react-icons/io5";

const tableHeader = [
    {
        text: "",
        width: "5",
    },
    {
        text: "Tag Name",
        width: "90",
    },
    {
        text: "",
        width: "5",
    },
];

const TagsManager = (props) => {
    const dispatch = useDispatch();
    const dataFlag = useSelector((store) => store.dataFlag);
    const [data, setData] = useState(null);
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(true);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        fetchTags();
        dispatch(Actions.setPageName("Tags"));
    }, []);

    useEffect(() => {
        if (dataFlag) {
            fetchTags();
        }
    }, [dataFlag]);

    const fetchTags = async () => {
        const tags = await getTags();
        dispatch(Actions.dataReloaded());
        setData(tags);
    };

    const addNewTagHandler = () => {
        dispatch(
            Actions.addPopup({
                type: POPUP_TYPES.ADD_TAG,
            })
        );
    };

    const handleSelect = (id) => {
        const isExists = selected.indexOf(id) !== -1;
        if (isExists) {
            let updatedArr = selected.filter((item) => item !== id);
            setSelected(updatedArr);
        } else {
            setSelected((prev) => [...prev, id]);
        }
    };

    const handleSelectAllNone = () => {
        if (data) {
            if (selectAll) {
                let newArr = [];
                Object.keys(data).map((key) => newArr.push(key));
                setSelected(newArr);
            } else {
                setSelected([]);
            }
            setSelectAll((prev) => !prev);
        }
    };

    const handleRemove = async (id) => {
        if (typeof id === "string" || selected.length > 0) {
            setDisable(true);
            if (typeof id === "string") {
                await removeTag(id);
            } else if (selected.length > 0) {
                for (let key of selected) {
                    await removeTag(key);
                }
                setSelected([]);
            }
            fetchTags();
            dispatch(
                Actions.addNotification({
                    title: "Action completed!",
                    text:
                        (typeof id === "string" ? capitalize(id) : "Tags") +
                        " removed successfully!",
                    status: "success",
                    duration: 3000,
                })
            );
            setDisable(false);
        }
    };

    return (
        <div className="tags-manager-wrapper">
            <div className="tags-manager-buttons-wrapper">
                <div className="left-side">
                    <Button
                        text="Select All/None"
                        onClick={handleSelectAllNone}
                        disabled={disable}
                    />
                    <Button
                        text="Remove Selected"
                        onClick={handleRemove}
                        disabled={disable}
                    />
                </div>
                <div className="right-side">
                    <Button
                        text="Add New"
                        onClick={addNewTagHandler}
                        disabled={disable}
                    />
                </div>
            </div>
            <div className="main-area-wrapper">
                {data && (
                    <Table
                        selected={selected}
                        header={tableHeader}
                        onRemove={handleRemove}
                    >
                        {Object.keys(data).map((key, idx) => (
                            <div
                                key={key}
                                className={
                                    "table-row" +
                                    ((idx + 1) % 2 === 0 ? " even" : "")
                                }
                            >
                                <Cell
                                    width="5"
                                    data={
                                        <Checkbox
                                            name={key}
                                            checked={
                                                selected.indexOf(key) !== -1
                                            }
                                            onChange={handleSelect}
                                        />
                                    }
                                    className="check-box"
                                />
                                <Cell width="90" data={data[key]} />
                                <Cell
                                    width="5"
                                    data={<IoTrashOutline />}
                                    className="trash-bin"
                                    onClick={handleRemove.bind(this, key)}
                                />
                            </div>
                        ))}
                    </Table>
                )}
                {!data && <LoadingIndicator />}
            </div>
        </div>
    );
};

export default TagsManager;
