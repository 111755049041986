import React, { useEffect, useState, useRef } from "react";
import app from "../../lib/API/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";
import Actions from "../../redux/actions";
import { convertToUser } from "../../lib/API/auth";
import { useAuthState } from "react-firebase-hooks/auth";

const Auth = () => {
    const dispatch = useDispatch();
    const [user, loading, error] = useAuthState(getAuth(app));

    useEffect(() => {
        if (user) {
            const userData = convertToUser(user);
            dispatch(Actions.signIn({ ...userData }));
        } else {
            dispatch(Actions.signOut());
        }
    }, [user]);

    return <></>;
};

export default Auth;
