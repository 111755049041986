import React, { useEffect, useState, useRef } from "react";
import "./index.scss";

const FloatTextArea = (props) => {
    const {
        value,
        label,
        maxLength,
        onChange,
        isValid,
        errMsg,
        name,
        resize = "none",
        className = "",
        showError = false,
        required = false,
    } = props;

    const [errorVisible, setErrorVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [labelSize, setLabel] = useState({
        width: 0,
        height: 0,
    });
    const inputRef = useRef();
    const labelRef = useRef();

    useEffect(() => {
        setErrorVisible(showError);
    }, [showError]);

    useEffect(() => {
        if (value !== "" && value !== undefined) {
            setIsFocused(true);
        } else {
            if (document.activeElement !== inputRef.current) {
                setIsFocused(false);
            }
        }
    }, [value]);

    useEffect(() => {
        let fontSize = getComputedStyle(labelRef.current).fontSize;
        fontSize = parseFloat(fontSize);

        setLabel({
            width: 12 * 0.6 * (label.length + 2),
            height: fontSize,
        });
    }, []);

    const onBlur = () => {
        let text = inputRef.current.value;
        if (text === "" || text === undefined) {
            setIsFocused(false);
        }
    };

    const onValueChange = (e) => {
        onChange(e);
        if (e.target.value === "") {
            setIsFocused(true);
        }
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    const onLabelClick = () => {
        inputRef.current.focus();
    };

    const floatClass = isFocused ? "active" : "";
    const errorClass = errorVisible ? "show" : "";
    return (
        <div className={"float-text-area-wrapper " + className}>
            <span
                className={`float-label-background ${floatClass}`}
                style={{
                    width: labelSize.width - 2,
                    height: labelSize.height - 2,
                }}
            ></span>
            <span
                ref={labelRef}
                className={`float-label ${floatClass}`}
                onClick={onLabelClick}
            >
                {label}
                {required && <span className="required-indicator">*</span>}
            </span>
            <textarea
                className={`float-input ${errorClass}`}
                ref={inputRef}
                value={value}
                maxLength={maxLength}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onValueChange}
                name={name}
                style={{
                    resize: resize,
                }}
            />
            <div className={`error-wrapper ${errorClass}`}>
                <div className="arrow-left"></div>
                <div className={`float-error`}>{errMsg}</div>
            </div>
        </div>
    );
};

export default FloatTextArea;
