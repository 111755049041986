import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let stateActions = {};
let stateReducers = {};

const scrollSlice = createSlice({
    name: "scroll",
    initialState: {
        int: 0,
        float: 0.0,
    },
    reducers: {
        setScroll: (state, action) => {
            return {
                int: Math.floor(action.payload),
                float: action.payload,
            };
        },
    },
});

Slices.push(scrollSlice);

const deviceStateSlice = createSlice({
    name: "deviceState",
    initialState: "desktop",
    reducers: {
        setDeviceState: (state, action) => {
            const states = {
                0: { type: "desktop", text: "desktop_max" },
                1: { type: "desktop", text: "desktop_large" },
                2: { type: "desktop", text: "desktop" },
                3: { type: "mobile", text: "tablet" },
                4: { type: "mobile", text: "mobile" },
            };
            return states[action.payload];
        },
    },
});

Slices.push(deviceStateSlice);

const sideNavSlide = createSlice({
    name: "dashboardSide",
    initialState: false,
    reducers: {
        toggleDashboardSideNav: (state) => {
            return !state;
        },
    },
});

Slices.push(sideNavSlide);

const userSlice = createSlice({
    name: "userData",
    initialState: {},
    reducers: {
        signIn: (state, action) => {
            return { ...action.payload };
        },
        signOut: () => {
            return {};
        },
    },
});

Slices.push(userSlice);

const dataFlagSlice = createSlice({
    name: "dataFlag",
    initialState: false,
    reducers: {
        reloadData: () => {
            return true;
        },
        dataReloaded: () => {
            return false;
        },
    },
});

Slices.push(dataFlagSlice);

const pageNameSlice = createSlice({
    name: "pageName",
    initialState: null,
    reducers: {
        setPageName: (state, action) => {
            return action.payload;
        },
    },
});

Slices.push(pageNameSlice);

for (let Slice of Slices) {
    stateActions = { ...stateActions, ...Slice.actions };
    let reducer = { [Slice.name]: Slice.reducer };
    stateReducers = { ...stateReducers, ...reducer };
}

export { stateActions };
export { stateReducers };
