import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { generateUniqueKey } from "../../utilites";
import { BiImageAdd } from "react-icons/bi";
import { AiOutlineHome, AiOutlineDelete, AiOutlineTag } from "react-icons/ai";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import "./index.scss";
import TabNavigator from "./components/TabNavigator";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../redux/actions";

const screens = [
    {
        id: generateUniqueKey(8),
        icon: <AiOutlineHome />,
        text: "Home",
        path: "/dashboard",
    },
    {
        id: generateUniqueKey(8),
        icon: <BiImageAdd />,
        text: "Add Image",
        path: "/dashboard/add-new-image",
    },
    {
        id: generateUniqueKey(8),
        icon: <AiOutlineDelete />,
        text: "Remove Image",
        path: "/dashboard/remove-image",
    },
    {
        id: generateUniqueKey(8),
        icon: <AiOutlineTag />,
        text: "Tags Manager",
        path: "/dashboard/tags-manager",
    },
    {
        id: generateUniqueKey(8),
        icon: <MdOutlineConnectWithoutContact />,
        text: "Contact Manager",
        path: "/dashboard/contacts-manager",
    },
];

const Dashboard = (props) => {
    const userData = useSelector((store) => store.userData);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Actions.setPageName("Dashboard"));
    }, []);

    return (
        <div className="dashboard-wrapper">
            {!userData.uid && <Navigate to={"/"} replace />}
            <TabNavigator screens={screens} />
            <div className="outlet-wrapper">
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;
