import React, { useEffect, useState, useRef } from 'react';
import { IoIosClose } from 'react-icons/io';
import './index.scss';


const DrawerNavigator = (props) => {
    const { onClick, onBlur, isOpen } = props;

    const openClass =  isOpen ? "open":"";
    return (
        <>
            <div className={`drawer-navigator-wrapper ${openClass}`}>
                <div className={`backdrop ${openClass}`} onClick={onBlur}></div>
                <div className={`overlay ${openClass}`}>
                    <div className="item right">
                        <IoIosClose onClick={onBlur} className="clickable"/>
                    </div>
                    <div className="item">
                        Home
                    </div>
                    <div className="item">
                        Gallery
                    </div>
                    <div className="item">
                        About
                    </div>
                    <div className="item">
                        Contact
                    </div>
                </div>
            </div>
        </>
    );
};

export default DrawerNavigator;