import React, { useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import "./index.scss";

const Checkbox = (props) => {
    const { onChange = () => {}, name, checked = false } = props;

    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const checkHandler = () => {
        setIsChecked((prev) => !prev);
        onChange(name.toLowerCase());
    };
    const checkedClassName = isChecked ? "checked" : "";
    return (
        <div
            className={`checkbox-box ${checkedClassName}`}
            onClick={checkHandler}
        >
            {isChecked && (
                <div className="check-mark">
                    <BsCheckLg />
                </div>
            )}
        </div>
    );
};

export default Checkbox;
