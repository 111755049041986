import React, { useEffect, useState } from "react";
import "./index.scss";

import { IoIosClose } from "react-icons/io";

const BasePopup = (props) => {
    const { children, icon, title, onClose, myRef, payload } = props;
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 50);
    }, []);

    const slideIn = animate ? "slide-down" : "";
    return (
        <div className={`overlay-wrapper ${slideIn}`} ref={myRef}>
            <div className="popup-header-wrapper">
                <div className="popup-header">
                    <div className="popup-header-title-wrapper">
                        {icon && (
                            <div className="popup-header-icon">{icon}</div>
                        )}
                        <div className="popup-header-title">{title}</div>
                    </div>
                    <div className="popup-header-close-icon" onClick={onClose}>
                        <IoIosClose />
                    </div>
                </div>
            </div>

            <div className="main-wrapper">
                {children}
                {payload}
            </div>
        </div>
    );
};

export default BasePopup;
