import React, { useState } from 'react';
import { AiOutlineVideoCameraAdd } from 'react-icons/ai';
import { BsTrashFill } from 'react-icons/bs';
import AddOverlay from '../add-overlay';
import './index.scss';

const EditableVideo = (props) => {
    const { value, className, placeholder, onChange, type } = props;


    const onChangeLocal = (link) => { 
        try {
            const params = (new URL(link)).searchParams;
            const embedLink = `https://www.youtube.com/embed/${params.get('v')}`;
            onChange(embedLink)
        } catch(e) {
            resetSrc()
        }
    }

    const resetSrc = () => { 
        onChange(null);
    }

    return (
    <div className={`editable-video-wrapper ${className}`}>
        <div className="iframe-wrapper">
            {value && <> 
                <iframe src={value} width="100%" height="100%" /> 
                <div className="remove-btn" onClick={resetSrc}> 
                    <BsTrashFill /> 
                </div>
            </>}
            {!value && (
            <AddOverlay 
                onSubmit={onChangeLocal}
                icon={<AiOutlineVideoCameraAdd/>} 
                windowTitle="Insert Video Link" 
                placeholder="Video Link" 
            />)}
            {/* <div className="test"></div> */}
        </div>
    </div>);
}

export default EditableVideo;