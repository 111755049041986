import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";

const Tab = (props) => {
    const linkRef = useRef();
    const { path, text, icon, className = "", onClick = false } = props;

    const [hover, setHover] = useState(false);

    useEffect(() => {
        document.addEventListener("mouseover", handleHover);

        return () => {
            document.removeEventListener("mouseover", handleHover);
        };
    }, []);

    const handleHover = (e) => {
        if (
            e.target === linkRef.current ||
            linkRef.current.contains(e.target)
        ) {
            setHover(true);
        } else {
            setHover(false);
        }
    };

    const hoverClass = hover ? "hover" : "";
    return (
        <>
            {typeof onClick === "function" ? (
                <div
                    onClick={onClick}
                    className={`side-nav-tab-wrapper ${hoverClass} ${className}`}
                    ref={linkRef}
                >
                    <div className="icon-wrapper">{icon}</div>
                    <div className={`label-wrapper ${hoverClass}`}>{text}</div>
                </div>
            ) : (
                <NavLink
                    to={path}
                    className={`side-nav-tab-wrapper ${hoverClass} ${className}`}
                    ref={linkRef}
                >
                    <div className="icon-wrapper">{icon}</div>
                    <div className={`label-wrapper ${hoverClass}`}>{text}</div>
                </NavLink>
            )}
        </>
    );
};

export default Tab;
