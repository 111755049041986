import React from "react";
import ProfilePic from "../../../assets/images/profile-pic.png";
import "./index.scss";

const About = (props) => {
    return (
        <div className="about-wrapper">
            <div className="about-left">
                <img
                    src={ProfilePic}
                    alt="Benny Kerido"
                    className="about-pic"
                />
            </div>
            <div className="about-right">
                <p className="about-paragraph">
                    I am a creative individual, who loves new challenges. In my
                    spare time I love to do photography and hike in nature. I
                    have experience with meeting deadlines, working with
                    co-workers and managing co-workers from previous job as
                    electritian in construction.
                </p>
            </div>
        </div>
    );
};

export default About;
