import { configureStore } from "@reduxjs/toolkit";
import Reducers from "./reducers";

const Store = configureStore({
    reducer: Reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
});

export default Store;
