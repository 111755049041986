import React from 'react';
import { generateUniqueKey } from '../../../../utilites';
import * as TYPES from '../../../constants/paragraph-types';
import EditableHeadline from '../editable-headline';
import EditableImage from '../editable-image';
import EditableText from '../editable-text';
import EditableVideo from '../editable-video';
import './index.scss';

const EditableParagraph = (props) => {
    const { id, para, classNames, onChange } = props;
    return (
    <div className='editable-paragraph-wrapper'>
        {getParagraph(id, para, onChange, classNames)}
    </div>);
}

export default EditableParagraph;

const getParagraph = (id, para, onChange, classNames) => {
    const { key, type, value, placeholder } = para; 
    const settings = {
        key: key ? key:generateUniqueKey(4),
        type: type,
        value: value,
        className: getClassName(type, classNames),
        onChange: onChange.bind(this, id),
        placeholder: placeholder
    }
    switch(type) {
        case TYPES.HEADLINE:
            return  <EditableHeadline {...settings} />
        case TYPES.IMAGE:
            return <EditableImage {...settings} />
        case TYPES.VIDEO:
            return <EditableVideo { ...settings } />
        case TYPES.TEXT:
            return <EditableText { ...settings } />
        }
}

const getClassName = (type, classNames) => {
    const { headline, paragraph, image, video } = classNames;
    switch(type){
        case TYPES.HEADLINE:
            return headline;
        case TYPES.IMAGE:
            return image;
        case TYPES.TEXT:
            return paragraph;
        case TYPES.VIDEO:
            return video;
        default: 
            return "";
    }
}
