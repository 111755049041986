import React, { useEffect, useState, useRef } from 'react';
import './index.scss';


const EditableText = (props) => {
    return (
        <div className='editable-text-wrapper'>
            
        </div>
    );
};

export default EditableText;