import React, { useEffect } from "react";
import "./App.scss";

/* ------- SCREENS ------- */
import Header           from "./components/Header";
import Home             from "./screens/Home";
import Dashboard        from "./screens/Dashboard";
import AddNew           from "./screens/Dashboard/screens/AddNew";
import RemovePhoto      from "./screens/Dashboard/screens/RemovePhoto";
import TagsManager      from "./screens/Dashboard/screens/TagsManager";
import ContactManager   from "./screens/Dashboard/screens/ContactManager";
import Document         from "./screens/Documents";
import PrivacyPolicy    from "./screens/Documents/PrivacyPolicy";
import Terms            from "./screens/Documents/Terms";
import Footer           from "./screens/sections/Footer";

/* ------- ROUTER ------- */
import DocumentTitle        from "./components/Router/DocumentTitle";
import ScrollToTop          from "./components/Router/ScrollToTop";
import { Routes, Route }    from "react-router-dom";

/* ------- REDUX ------- */
import Actions                      from "./redux/actions";
import { useDispatch, useSelector } from "react-redux";

/* ------- API ------- */
import Auth                                     from "./components/Auth";
import { checkIfUserIsLogged, convertToUser }   from "./lib/API/auth";

/* ------- POPUPS ------- */
import Popups from "./popups";

/* ------- COMPONENTS ------- */
import Notifications                from "./components/Notifications";
import BackToTop                    from "./components/BackToTop";
import StateIndicator               from "./components/StateIndicator";
import Editor from "./contact-page/editor";

function App() {
    const dispatch = useDispatch();
    const popupsArray = useSelector((store) => store.popupsArray);
    const notifications = useSelector((store) => store.notifications);
    const pageName = useSelector((store) => store.pageName);

    useEffect(() => {
        document.addEventListener("scroll", setActiveOnScroll, true);
        return document.removeEventListener("scroll", setActiveOnScroll);
    }, []);

    const checkLogged = async () => {
        const user = await checkIfUserIsLogged();
        if (user) {
            dispatch(Actions.signIn(convertToUser(user)));
        }
    };

    useEffect(() => {
        checkLogged();
    }, []);

    const setActiveOnScroll = (e) => {
        let scroll = window.scrollY;
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        );
        const calculatedScroll = scroll / vh;
        dispatch(Actions.setScroll(calculatedScroll));
    };

    return (
        <div className="App">
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />}>
                    <Route path="add-new-image" element={<AddNew />} />
                    <Route path="tags-manager" element={<TagsManager />} />
                    <Route path="remove-image" element={<RemovePhoto />} />
                    <Route
                        path="contacts-manager"
                        element={<ContactManager />}
                    />
                </Route>
                <Route path="/docs" element={<Document />}>
                    <Route
                        path="privacy-policy"
                        element={<PrivacyPolicy />}
                    />
                    <Route path="terms-of-use" element={<Terms />} />
                </Route>
                <Route path="/editor" element={<Editor/>} />
            </Routes>
            <Footer />
            <ScrollToTop />
            <DocumentTitle />
            <BackToTop />
            <Auth />
            {/* <StateIndicator /> */}
            {popupsArray.length > 0 && <Popups />}
            {notifications.length > 0 && <Notifications />}
        </div>
    );
}

export default App;

export const sendTo = (place) => {
    let path;
    switch (place) {
        case "facebook":
            path = "https://www.facebook.com/bennykeridophotography";
            break;
        case "instagram":
            path = "https://www.instagram.com/bennykerido_photography/";
            break;
        case "whatsapp":
            path = "https://www.wa.me/972525241194";
            break;
    }
    if (path) {
        window.location.href = path;
    }
};
