import React from "react";
import { generateUniqueKey } from "../../utilites";
import "./index.scss";

const data = [
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
    {
        id: generateUniqueKey(16),
        name: "Lorem Ipsum",
        imageUrl: "https://picsum.photos/1920/1080",
    },
];

const ImageGrid = (props) => {
    const { images } = props;

    const imageClickHandler = (id) => {};

    return (
        <div className="image-grid-wrapper">
            {data.map((image, idx) => {
                return (
                    <img
                        key={image.id}
                        src={image.imageUrl}
                        alt={image.name}
                        className={"image-grid-item no-" + idx}
                        onClick={imageClickHandler.bind(this, idx)}
                    />
                );
            })}
        </div>
    );
};

export default ImageGrid;
