import "./index.scss";
import React from "react";
import Contact from "../sections/Contact";
import Splash from "../sections/Splash";
import About from "../sections/About";
import Gallery from "../sections/Gallery";

const Home = (props) => {
    return (
        <>
            <div className="screen first">
                <Splash id="home" />
            </div>
//             <div className="screen">
//                 <Gallery id="galley" />
//             </div>
//             <div className="screen">
//                 <About id="about" />
//             </div>
//             <div className="screen">
//                 <Contact id="contact" />
//             </div>
        </>
    );
};

export default Home;
