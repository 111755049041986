import React from "react";
import "./index.scss";

const Cell = (props) => {
    const {
        data,
        isHeader,
        width = "10",
        onClick = () => {},
        className = "",
        onPopup = () => {},
    } = props;

    const getText = (data) => {
        if (typeof data === "string") {
            if (data.length > 65) {
                return (
                    <div className="clickable" onClick={onPopup}>
                        {data.substring(0, 62) + "..."}
                    </div>
                );
            }
        }
        return data;
    };

    const headerClass = isHeader ? "header" : "";
    return (
        <div
            className={`cell-wrapper ${headerClass} ${className}`}
            style={{
                width: width + "%",
            }}
            onClick={onClick}
        >
            {getText(data)}
        </div>
    );
};

export default Cell;
